<template>
  <div>
    <b-row>
      <b-col class="mb-1">
        <h3>Account type:</h3>
        <treeselect
          v-model="advancedFilter.account_type"
          :multiple="true"
          value-consists-of="LEAF_PRIORITY"
          :options="accountTypesOptions"
        />
      </b-col>
      <b-col
        xl="6"
        lg="5"
        md="3"
        sm="12"
      >
        <b-form-group
          class="mr-auto my-1 mt-2"
        >
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="search"
              type="search"
              placeholder="Search by account number or email"
              @keyup.enter="advancedFilter.search = search"
            />
            <b-input-group-append>
              <b-button
                variant="outline-info"
                @click="clearSearch"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                variant="outline-primary"
                :disabled="!search"
                @click="advancedFilter.search = search"
              >
                <feather-icon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <data-table
      ref="phasesTable"
      :module-name="'phasesList'"
      :fields="fields"
      :filters="advancedFilter"
      :actions="actions"
      sort-by="state"
      :sort-desc="true"
      @detail="onDetail"
      @move="onMove"
      @document="openDocument"
      @hide="onHide"
      @edit="onEdit"
      @contract="onResendContract"
    >
      <template #cell(show_details)="row">
        <show-details :row="row" />
      </template>

      <template #row-details="row">
        <row-detail :row="row" />
      </template>

      <template #cell(type)="data">
        {{ accountNames[data.item.account_type_name] }} - {{ PHASES[data.item.account_subtype_name].title }}
      </template>

      <template #cell(tags)="data">
        <tags-list
          :tags="data.item.tags"
          :extras-tags="data.item.extras_tags"
        />
      </template>

      <template #cell(account_id)="data">
        <b-link :to="{name: 'account-detail', params: {id: data.item.account_id}}">
          {{ data.item.account_id }}
        </b-link>
        <risk-score-badge
          v-if="data.item.risk_score"
          class="mx-1"
          :score-results="data.item.risk_score_results"
          :score="data.item.risk_score"
        />
      </template>

      <template #cell(parent_id)="data">
        <b-link
          v-if="data.item.parent.account_id !== undefined"
          :to="{name: 'account-detail', params: {id: data.item.parent.account_id}}"
        >
          {{ data.item.parent.account_id }}
        </b-link>
      </template>

      <template #cell(balance)="data">
        <b-badge
          :variant="(parseFloat(data.item.balance, 10)>parseFloat(data.item.deposit,10)) ? 'success' : 'danger'"
        >
          {{ formatCurrency(data.item.balance, data.item.currency.label) }}
        </b-badge>
      </template>

      <template #cell(deposit)="data">
        {{ formatCurrency(data.item.deposit, data.item.currency.label) }}
      </template>

      <template #cell(user)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.user.id } }">
              {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              <risk-score-badge
                v-if="data.item.user.risk_score"
                class="mx-1"
                :score-results="data.item.user.risk_score_results"
                :score="data.item.user.risk_score"
              />
              <br>
              <small>{{ data.item.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.user.tags"
              :extras-tags="getUserExtraTags(data.item.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(created_at)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(start_datetime)="data">
        {{ formatDateTime(data.item.start_datetime) }}
      </template>

      <template #cell(end_datetime)="data">
        {{ formatDateTime(data.item.end_datetime) }}
      </template>

      <template #cell(close_datetime)="data">
        {{ formatDateTime(data.item.close_datetime) }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="`light-${stateVariants[data.item.state]}`">
          {{ data.item.state }}
        </b-badge>
        <span v-if="data.item.under_review">under review</span>
      </template>
    </data-table>
    <b-modal
      id="accountModal"
      title="Edit Account"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <account-form
        module-name="phasesList"
        :account="accountEdit"
        @submit="onEditSubmit()"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge, BLink, BButton, BRow, BCol, BFormGroup, BFormInput,
  BInputGroupAppend, BInputGroup, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { dispatch } from 'vuex-pathify'
import DataTable from '@/components/ui/tables/DataTable.vue'
import AccountForm from '@/components/forms/account/AccountForm.vue'
import { ACCOUNTS_SHORT, PHASES, ACCOUNT_TYPES_BY_PHASE } from '@/enums/accounts'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import AccountAPI from '@/api/account'
import store from '@/store'
import TagsList from '@/components/page/TagsList.vue'
import PandadocApi from '@/api/pandadoc'
import RiskScoreBadge from '@/components/ui/RiskScoreBadge.vue'

import ShowDetails from '@/components/ui/tables/ShowDetails.vue'
import RowDetail from '@/components/page/accounts/components/RowDetail.vue'

const actions = [
  {
    text: 'Move to next phase',
    icon: 'ChevronsUpIcon',
    emit: 'move',
  },
  {
    text: 'Account details',
    icon: 'SearchIcon',
    emit: 'detail',
  },
  {
    text: 'Show contract',
    icon: 'FileIcon',
    emit: 'document',
  },
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Resend contract',
    icon: 'SendIcon',
    emit: 'contract',
  },
  {
    text: 'Hide account',
    icon: 'EyeOffIcon',
    emit: 'hide',
  },
]

export default {
  components: {
    ShowDetails,
    RowDetail,
    TagsList,
    BBadge,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BModal,
    Treeselect,
    DataTable,
    AccountForm,
    RiskScoreBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      actions,
      PHASES,
      accountTypesOptions: ACCOUNT_TYPES_BY_PHASE,
      advancedFilter: {
        account_type: ['e8phase1', 'elev8phase1', 'e8trackphase1'],
        state: ['completed'],
        no_children_account: false,
        search: '',
      },
      search: '',
      stateVariants: {
        active: 'primary',
        failed: 'danger',
        completed: 'success',
      },
      fields: [
        'show_details',
        {
          key: 'type',
          sortable: false,
        },
        {
          key: 'tags',
          sortable: false,
        },
        {
          key: 'account_id',
          sortable: false,
        },
        {
          key: 'parent_id',
          sortable: false,
        },
        {
          key: 'deposit',
          sortable: false,
        },
        {
          key: 'balance',
          sortable: false,
        },
        {
          key: 'user',
          sortable: false,
        },
        {
          key: 'created_at',
          sortable: true,
        },
        {
          key: 'start_datetime',
          sortable: true,
        },
        {
          key: 'end_datetime',
          sortable: true,
        },
        {
          key: 'close_datetime',
          sortable: true,
        },
        {
          key: 'state',
          sortable: true,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      accountNames: ACCOUNTS_SHORT,
      accountEdit: null,
    }
  },
  methods: {
    formatCurrency,
    formatDateTime,
    getUserExtraTags,
    onEdit(item) {
      dispatch('accountsList/get', item.account_id).then(data => {
        this.accountEdit = data.account_data
        this.$bvModal.show('accountModal')
      })
    },
    onEditSubmit() {
      this.$bvModal.hide('accountModal')
    },
    onDetail(item) {
      this.$router.push({ name: 'account-detail', params: { id: item.id } })
    },
    onMove(item) {
      if (item.state === 'active') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Unable to level up active account',
            variant: 'danger',
          },
        })
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: 'User will be notified about this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, level up!',
      }).then(result => {
        if (result.isConfirmed) {
          AccountAPI.postNextAccountPhase(item.id).then(() => {
            this.$refs.phasesTable.reloadAll()
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Unable to proceed',
                icon: 'WarningIcon',
                text: 'Account is probably moved already',
                variant: 'warning',
              },
            })
            this.$refs.phasesTable.reloadAll()
          })
        }
      })
    },
    onHide(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `Account ${item.account_id} own by ${item.user.email} will be hide in phases section`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, hide!',
      }).then(result => {
        if (result.isConfirmed) {
          store.dispatch('phasesList/update', { id: item.id, data: { hideAt: new Date().toISOString() } })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hide account',
                  icon: 'EyeOffIcon',
                  text: 'Account was hide successfully.',
                  variant: 'success',
                },
              })
              this.$refs.phasesTable.reloadAll()
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hide account',
                  icon: 'XCircleIcon',
                  text: 'Hiding account failed.',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    openDocument(item) {
      window.open(`https://app.pandadoc.com/a/#/documents/${item.doc_id}`, '_blank')
    },
    onResendContract(item) {
      if (item.account_subtype_name !== 'funded' || item.confirmedAt) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Unable to proceed',
            icon: 'WarningIcon',
            text: 'Create contract is possible for funded and unconfirmed account only!',
            variant: 'danger',
          },
        })
        return
      }
      Swal.fire({
        title: 'Are you sure?',
        text: `Contract for account ${item.account_id} will be created.`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, create',
      }).then(result => {
        if (result.isConfirmed) {
          PandadocApi.postSendContract(item.id)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Contract created',
                  icon: 'CheckCircleIcon',
                  text: 'Contract created',
                  variant: 'success',
                },
              })
            })
        }
      })
    },
    clearSearch() {
      this.advancedFilter.search = ''
      this.search = ''
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
